$(function(){
  $("#typed").typed({
      stringsElement: $('#typed-strings'),
      typeSpeed: 120,
      backDelay: 2000,
      loop: true,
      contentType: 'text',
      loopCount: false,
  });
});

